
import { Comment, CommentBody, CommentBodyType, CommentReply, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import { CommentModule } from '../store'
import Content from './content.vue'
import Type from './type.vue'

@Component({
  components: {
    Content,
    Type
  },
  name: 'CommentThread'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly contentRef!: Content
  @Ref() readonly replyRef!: Content
  @Ref() readonly contentContainerRef!: HTMLElement
  @Ref() readonly replyContainerRef!: HTMLElement

  @Prop() readonly commentId!: string

  loading = true
  resolving = false
  replying = false
  replyButtonDisabled = true
  contentDividerTop = false
  contentDividerBottom = false
  replyDividerTop = false
  replyDividerBottom = false
  editing: string | null = null
  editingValue: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get comment () {
    return this.commentModule.activeComments[this.commentId] || this.commentModule.comments[this.commentId]
  }

  get diagramCommentId () {
    return Object.values(this.comment.diagrams)?.[0]?.commentId
  }

  get commentReplies () {
    return Object.values(this.commentModule.commentReplies)
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get replyCount () {
    return this.comment.replyCount
  }

  get colors (): Record<CommentBodyType, string> {
    return {
      idea: 'white',
      inaccurate: 'error',
      'new-idea': 'white',
      'new-inaccurate': 'error',
      'new-question': 'info',
      question: 'info'
    }
  }

  get resolvable () {
    return this.comment.body.type === 'question' || this.comment.body.type === 'inaccurate'
  }

  get resolved () {
    return this.comment.body.status === 'resolved' || this.comment.body.status === 'dismissed'
  }

  get dismissable () {
    return this.comment.body.type === 'idea'
  }

  get pinned () {
    return !!this.comment.body.showContentPreview
  }

  get createdBy () {
    return (comment: Comment | CommentReply) => {
      if (this.createdByYou(comment)) {
        return 'You'
      } else if (this.currentLandscape?.organizationId) {
        return this.organizationModule.organizationUsers[this.currentLandscape.organizationId]?.[comment.createdById]?.name || ''
      }
    }
  }

  get createdByYou () {
    return (comment: Comment | CommentReply) => this.userModule.user?.id === comment.createdById
  }

  @Watch('replyCount')
  onReplyCountChanged (replyCount: number, prevReplyCount: number) {
    if (replyCount !== prevReplyCount) {
      this.loadProcess()
    }
  }

  mounted () {
    this.load()
  }

  updateScroll () {
    this.contentDividerTop = this.contentContainerRef?.scrollTop > 0
    this.contentDividerBottom = this.contentContainerRef?.scrollTop + this.contentContainerRef?.clientHeight < this.contentContainerRef?.scrollHeight

    this.replyDividerTop = this.replyContainerRef?.scrollTop > 0
    this.replyDividerBottom = this.replyContainerRef?.scrollTop + this.replyContainerRef?.clientHeight < this.replyContainerRef?.scrollHeight
  }

  async load () {
    try {
      this.loading = true
      await this.loadProcess()
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }

  async loadProcess () {
    if (this.comment.replyCount) {
      await this.commentModule.commentRepliesList({
        commentId: this.commentId,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })
    } else {
      this.commentModule.setCommentReplies([])
    }

    await this.$nextTick()
    this.$emit('resize')
    this.updateScroll()
  }

  async reply () {
    try {
      this.replying = true

      await this.commentModule.commentReplyCreate({
        commentId: this.commentId,
        landscapeId: this.currentLandscapeId,
        props: {
          content: this.replyRef.getValue()
        },
        versionId: this.currentVersionId
      })

      this.replyRef.setValue('')

      this.contentContainerRef.scrollTop = this.contentContainerRef.scrollHeight - this.contentContainerRef.clientHeight
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.replying = false
    }
  }

  async resolve () {
    if (this.comment.body.status === 'open') {
      if (this.resolving) {
        return
      }
      this.resolving = true

      await new Promise(resolve => setTimeout(resolve, 500))
    }

    if (this.comment.body.type === 'question' || this.comment.body.type === 'inaccurate') {
      const revertTasks: Task[] = [{
        id: this.commentId,
        props: {
          body: {
            ...this.comment.body,
            status: this.comment.body.status === 'open' ? 'open' : 'resolved'
          }
        },
        type: 'comment-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { comment, commentUpdate } = this.commentModule.generateCommentCommit(this.commentId, {
        body: {
          ...this.comment.body,
          status: this.comment.body.status === 'open' ? 'resolved' : 'open'
        }
      })
      this.commentModule.setCommentVersion(comment)
      this.commentModule.setActiveCommentVersion(comment)
      this.editorModule.addToTaskQueue({
        func: () => this.commentModule.commentUpdate({
          commentId: comment.id,
          landscapeId: this.currentLandscapeId,
          props: commentUpdate,
          versionId: this.currentVersionId
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: comment.id,
          props: commentUpdate,
          type: 'comment-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }

    this.resolving = false
  }

  async pinToggle () {
    const revertTasks: Task[] = [{
      id: this.commentId,
      props: {
        body: {
          ...this.comment.body
        }
      },
      type: 'comment-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const newBody: CommentBody = {
      ...this.comment.body
    }
    if (this.comment.body.showContentPreview) {
      delete newBody.showContentPreview
    } else {
      newBody.showContentPreview = true
    }

    const { comment, commentUpdate } = this.commentModule.generateCommentCommit(this.commentId, {
      body: newBody
    })
    this.commentModule.setCommentVersion(comment)
    this.commentModule.setActiveCommentVersion(comment)
    this.editorModule.addToTaskQueue({
      func: () => this.commentModule.commentUpdate({
        commentId: comment.id,
        landscapeId: this.currentLandscapeId,
        props: commentUpdate,
        versionId: this.currentVersionId
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: comment.id,
        props: commentUpdate,
        type: 'comment-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }

  dismiss () {
    if (this.comment.body.type === 'idea') {
      const revertTasks: Task[] = [{
        id: this.commentId,
        props: {
          body: this.comment.body
        },
        type: 'comment-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { comment, commentUpdate } = this.commentModule.generateCommentCommit(this.commentId, {
        body: {
          ...this.comment.body,
          status: this.comment.body.status === 'active' ? 'dismissed' : 'active'
        }
      })
      this.commentModule.setCommentVersion(comment)
      this.commentModule.setActiveCommentVersion(comment)
      this.editorModule.addToTaskQueue({
        func: () => this.commentModule.commentUpdate({
          commentId: comment.id,
          landscapeId: this.currentLandscapeId,
          props: commentUpdate,
          versionId: this.currentVersionId
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: comment.id,
          props: commentUpdate,
          type: 'comment-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
  }

  saveComment (comment: Comment) {
    if (typeof this.editingValue === 'string' && this.editingValue !== comment.body.content) {
      const revertTasks: Task[] = [{
        id: this.commentId,
        props: {
          body: this.comment.body
        },
        type: 'comment-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { comment, commentUpdate } = this.commentModule.generateCommentCommit(this.commentId, {
        body: {
          ...this.comment.body,
          content: this.editingValue
        }
      })
      this.commentModule.setCommentVersion(comment)
      this.commentModule.setActiveCommentVersion(comment)
      this.editorModule.addToTaskQueue({
        func: () => this.commentModule.commentUpdate({
          commentId: comment.id,
          landscapeId: this.currentLandscapeId,
          props: commentUpdate,
          versionId: this.currentVersionId
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: comment.id,
          props: commentUpdate,
          type: 'comment-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }

    this.editing = null
    this.editingValue = null
  }

  async saveCommentReply (commentReply: CommentReply) {
    if (commentReply && typeof this.editingValue === 'string' && this.editingValue !== commentReply.content) {
      await this.commentModule.commentReplyUpdate({
        commentId: commentReply.commentId,
        commentReplyId: commentReply.id,
        landscapeId: this.currentLandscapeId,
        props: {
          content: this.editingValue
        },
        versionId: this.currentVersionId
      })
    }

    this.editing = null
    this.editingValue = null
  }
}
