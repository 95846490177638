
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../../helpers/analytics'
import BarItem from './item.vue'

@Component({
  components: {
    BarItem
  },
  name: 'CatalogTechnologyBar'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get diagramObjectTechnologyCount () {
    return Object
      .values(this.currentDiagram?.objects || {})
      .map(o => this.modelModule.objects[o.modelId]?.technologies ? Object.values(this.modelModule.objects[o.modelId].technologies) : [])
      .flat()
      .reduce<Record<string, number>>((p, c) => ({
        ...p,
        [c.id]: (p[c.id] || 0) + 1
      }), {})
  }

  get diagramConnectionTechnologyCount () {
    return Object
      .values(this.currentDiagram?.connections || {})
      .map(o => o.modelId && this.modelModule.connections[o.modelId]?.technologies ? Object.values(this.modelModule.connections[o.modelId].technologies) : [])
      .flat()
      .reduce<Record<string, number>>((p, c) => ({
        ...p,
        [c.id]: (p[c.id] || 0) + 1
      }), {})
  }

  get diagramTechnologies () {
    return Object.keys({
      ...this.diagramObjectTechnologyCount,
      ...this.diagramConnectionTechnologyCount
    }).map(o => this.modelModule.technologies[o])
  }

  get diagramTechnologiesArray () {
    return Object.values(this.diagramTechnologies).sort((a, b) => a.name.localeCompare(b.name))
  }

  get diagramTechnologyIds () {
    return this.diagramTechnologiesArray.map(o => o.id)
  }

  get diagramTechnologyCount () {
    return {
      ...this.diagramObjectTechnologyCount,
      ...this.diagramConnectionTechnologyCount
    }
  }

  get currentTechnologiesPinned () {
    return this.diagramTechnologyIds.every(o => this.overlayIdsPinned.includes(o))
  }

  pinAll () {
    if (this.currentTechnologiesPinned) {
      this.$replaceQuery({
        overlay_pin: this.$removeQueryArray(...this.diagramTechnologyIds)
      })

      analytics.catalogTechnologyUnpinAll.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    } else {
      this.$replaceQuery({
        overlay_pin: this.$unionQueryArray(...this.diagramTechnologyIds)
      })

      analytics.catalogTechnologyPinAll.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
