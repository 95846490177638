import { Application, Ticker } from '@icepanel/app-canvas'

import * as env from './env'

Ticker.shared.stop()

let app: Application<HTMLCanvasElement> | undefined

export default () => {
  if (!app) {
    app = new Application<HTMLCanvasElement>({
      antialias: !env.IS_SAFARI,
      autoDensity: true,
      backgroundAlpha: 0,
      eventFeatures: {
        click: true,
        globalMove: false,
        move: true,
        wheel: false
      },
      eventMode: 'passive',
      resolution: window.devicePixelRatio
    })
  }
  return app
}
