
import { CommentBodyType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'

import { CommentModule } from '../../store'
import Thread from '../thread.vue'

@Component({
  components: {
    Menu,
    Thread
  },
  name: 'CommentMenuThread'
})
export default class extends Vue {
  commentModule = getModule(CommentModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly commentId!: string
  @Prop({ default: false }) readonly disabled?: boolean
  @Prop() readonly position!: { x: number, y: number }

  get comment () {
    return this.commentModule.activeComments[this.commentId]!
  }

  get colors (): Record<CommentBodyType, string> {
    return {
      idea: 'white',
      inaccurate: 'error',
      'new-idea': 'white',
      'new-inaccurate': 'error',
      'new-question': 'info',
      question: 'info'
    }
  }

  mounted () {
    this.menuRef.open()
  }

  updateDimensions () {
    this.menuRef.updateDimensions()
  }
}
