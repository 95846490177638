import remarkGfm from 'remark-gfm'
import markdown from 'remark-parse'
import unified from 'unified'

import { Node } from '@/types/markdown'

export default (text: string) => {
  const processor = unified().use(remarkGfm).use(markdown)
  const tree = processor.parse(text) as Node

  let preview = ''

  const walkNode = (node: Node) => {
    switch (node.type) {
      case 'heading': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'link': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'strong': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'delete': {
        node.children.map(o => walkNode(o))
        break
      }

      case 'emphasis': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'paragraph': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'table': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'tableRow': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'tableCell': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'list': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'listItem': {
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'blockquote': {
        node.children.map(o => walkNode(o))
        break
      }

      case 'text': {
        preview = preview || node.value
      }
    }
  }

  tree.children.forEach(o => walkNode(o))

  return preview
}
