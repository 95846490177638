import { format, startOfMonth, startOfToday, startOfWeek, startOfYesterday } from 'date-fns'

export const getDateKeys = () => {
  const now = new Date()
  const nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())

  const startToday = startOfToday()
  const todayStartUtc = Date.UTC(startToday.getUTCFullYear(), startToday.getUTCMonth(), startToday.getUTCDate(), startToday.getUTCHours(), startToday.getUTCMinutes(), startToday.getUTCSeconds())
  const todayStartAtKey = format(todayStartUtc, 'yyyy-MM-dd', { weekStartsOn: 1 })

  const startYesterday = startOfYesterday()
  const yesterdayStartUtc = Date.UTC(startYesterday.getUTCFullYear(), startYesterday.getUTCMonth(), startYesterday.getUTCDate(), startYesterday.getUTCHours(), startYesterday.getUTCMinutes(), startYesterday.getUTCSeconds())
  const yesterdayStartAtKey = format(yesterdayStartUtc, 'yyyy-MM-dd', { weekStartsOn: 1 })

  const weekStartAt = startOfWeek(nowUtc, { weekStartsOn: 1 })
  const weekStartAtKey = format(weekStartAt, 'yyyy-MM-dd', { weekStartsOn: 1 })

  const monthStartAt = startOfMonth(nowUtc)
  const monthStartAtKey = format(monthStartAt, 'yyyy-MM-dd', { weekStartsOn: 1 })

  return {
    monthStartAtKey,
    todayStartAtKey,
    weekStartAtKey,
    yesterdayStartAtKey
  }
}
