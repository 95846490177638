
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { ModelObjectTechnology } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import TagChip from '@/modules/tag/components/tag.vue'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../../helpers/analytics'

@Component({
  components: {
    TagChip
  },
  name: 'CatalogTechnologyBarItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly tagContainer!: HTMLElement

  @Prop() readonly technology!: ModelObjectTechnology
  @Prop() readonly count?: number

  hoverTag = false
  hoverMenu = false
  hoverFocus = false
  hoverHide = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get pinned () {
    return this.$queryArray('overlay_pin').includes(this.technology.id)
  }

  get hidden () {
    return this.$queryArray('overlay_hide').includes(this.technology.id)
  }

  get focused () {
    return this.$queryArray('overlay_focus').includes(this.technology.id)
  }

  get icon () {
    if (this.image) {
      if (this.hidden) {
        return '$fas-eye-slash'
      } else if (this.pinned || this.hoverTag) {
        return '$fas-map-pin'
      }
    }
  }

  get image () {
    return iconUrlForTheme(this.technology)
  }

  get colorMap () {
    if (this.hidden) {
      return TAG_COLOR_INACTIVE
    } else if (this.hoverTag || this.hoverMenu || this.pinned) {
      return TAG_COLOR_ACTIVE
    } else {
      return TAG_COLOR_INACTIVE
    }
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get highlight () {
    return !this.hidden && this.hoverTag
  }

  get hidePreview () {
    return !this.hidden && this.hoverHide
  }

  get unhidePreview () {
    return this.hidden && (this.hoverTag || this.hoverHide)
  }

  get focusPreview () {
    return !this.focused && this.hoverFocus
  }

  get unfocusPreview () {
    return this.focused && (this.hoverTag || this.hoverFocus)
  }

  @Watch('highlight')
  onHighlightChanged (highlight: boolean) {
    this.editorModule.setHighlight({
      enabled: highlight,
      id: this.technology.id
    })
  }

  @Watch('hidePreview')
  onHidePreviewChanged (hidePreview: boolean) {
    this.editorModule.setHidePreview({
      enabled: hidePreview,
      id: this.technology.id
    })
  }

  @Watch('unhidePreview')
  onUnhidePreviewChanged (unhidePreview: boolean) {
    this.editorModule.setUnhidePreview({
      enabled: unhidePreview,
      id: this.technology.id
    })
  }

  @Watch('focusPreview')
  onFocusPreviewChanged (focusPreview: boolean) {
    this.editorModule.setFocusPreview({
      enabled: focusPreview,
      id: this.technology.id
    })
  }

  @Watch('unfocusPreview')
  onUnfocusPreviewChanged (unfocusPreview: boolean) {
    this.editorModule.setUnfocusPreview({
      enabled: unfocusPreview,
      id: this.technology.id
    })
  }

  clickTag () {
    if (this.hidden) {
      this.unhide()
    } else if (this.focused) {
      this.unfocus()
    } else if (this.pinned) {
      this.unpin()
    } else if (!this.overlayIdsFocused.length) {
      this.pin()
    }
  }

  pin () {
    this.$replaceQuery({
      overlay_pin: this.$unionQueryArray(this.technology.id)
    })

    analytics.catalogTechnologyPin.track(this, {
      catalogTechnologyColor: this.technology.color,
      catalogTechnologyName: this.technology.name,
      catalogTechnologyNameShort: this.technology.nameShort,
      catalogTechnologyProvider: this.technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  unpin () {
    this.$replaceQuery({
      overlay_pin: this.$removeQueryArray(this.technology.id)
    })

    analytics.catalogTechnologyUnpin.track(this, {
      catalogTechnologyColor: this.technology.color,
      catalogTechnologyName: this.technology.name,
      catalogTechnologyNameShort: this.technology.nameShort,
      catalogTechnologyProvider: this.technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  clickHide () {
    if (this.hidden) {
      this.unhide()
    } else {
      this.hide()
    }
  }

  hide () {
    this.$replaceQuery({
      overlay_focus: undefined,
      overlay_hide: this.$unionQueryArray(this.technology.id),
      overlay_pin: this.$removeQueryArray(this.technology.id)
    })

    analytics.catalogTechnologyHide.track(this, {
      catalogTechnologyColor: this.technology.color,
      catalogTechnologyName: this.technology.name,
      catalogTechnologyNameShort: this.technology.nameShort,
      catalogTechnologyProvider: this.technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  unhide () {
    this.$replaceQuery({
      overlay_focus: undefined,
      overlay_hide: this.$removeQueryArray(this.technology.id)
    })

    analytics.catalogTechnologyUnhide.track(this, {
      catalogTechnologyColor: this.technology.color,
      catalogTechnologyName: this.technology.name,
      catalogTechnologyNameShort: this.technology.nameShort,
      catalogTechnologyProvider: this.technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  clickFocus () {
    if (this.focused) {
      this.unfocus()
    } else {
      this.focus()
    }
  }

  unfocus () {
    this.$replaceQuery({
      overlay_focus: this.$removeQueryArray(this.technology.id),
      overlay_hide: undefined,
      overlay_pin: this.$removeQueryArray(this.technology.id)
    })

    analytics.catalogTechnologyUnfocus.track(this, {
      catalogTechnologyColor: this.technology.color,
      catalogTechnologyName: this.technology.name,
      catalogTechnologyNameShort: this.technology.nameShort,
      catalogTechnologyProvider: this.technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  focus () {
    this.$replaceQuery({
      overlay_focus: [this.technology.id],
      overlay_hide: undefined,
      overlay_pin: [this.technology.id]
    })

    analytics.catalogTechnologyFocus.track(this, {
      catalogTechnologyColor: this.technology.color,
      catalogTechnologyName: this.technology.name,
      catalogTechnologyNameShort: this.technology.nameShort,
      catalogTechnologyProvider: this.technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  mouseOver (e: MouseEvent) {
    if (e.buttons === 0) {
      this.hoverTag = true
    }
  }

  mouseLeave () {
    this.hoverTag = false
  }
}
