
import { CommentBodyStatus, CommentBodyType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'CommentType'
})
export default class extends Vue {
  @Prop() readonly value?: CommentBodyType
  @Prop() readonly status?: CommentBodyStatus

  get item () {
    return this.items.find(o => o.id === this.value)
  }

  get resolved () {
    return this.status === 'resolved' || this.status === 'dismissed'
  }

  get items (): { id: CommentBodyType, title: string, titleCaption?: string, color: string }[] {
    return [
      {
        color: 'info',
        id: 'question',
        title: 'Question'
      },
      {
        color: 'error',
        id: 'inaccurate',
        title: this.resolved ? 'Inaccurate:' : 'Inaccurate: +1 inaccuracy',
        titleCaption: this.resolved ? 'Inaccuracy Score reset' : undefined
      },
      {
        color: 'white',
        id: 'idea',
        title: 'Idea'
      }
    ]
  }
}
