
import { CommentBody, CommentBodyType, CommentRequired, DiagramComment, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import randomId from '@/helpers/random-id'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { CommentModule } from '../../store'
import Content from '../content.vue'
import TypeSelection from '../type-selection.vue'

@Component({
  components: {
    Content,
    Menu,
    TypeSelection
  },
  name: 'CommentMenuCreate'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly contentRef!: Content
  @Ref() readonly menuRef!: Menu

  @Prop({ default: false }) readonly disabled?: boolean
  @Prop() readonly position!: { x: number, y: number }
  @Prop() readonly positionGlobal!: { x: number, y: number }
  @Prop() readonly diagramId!: string

  createButtonDisabled = true
  contentDividerTop = false
  contentDividerBottom = false

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentCommentHandleId () {
    return this.$queryValue('comment') as Exclude<CommentBodyType, 'idea' | 'question' | 'inaccurate'> | null
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get type (): Exclude<CommentBodyType, 'new-idea' | 'new-question' | 'new-inaccurate'> {
    switch (this.currentCommentHandleId) {
      case 'new-idea': return 'idea'
      case 'new-inaccurate': return 'inaccurate'
      case 'new-question': return 'question'
      case null: return 'question'
    }
  }

  get colors (): Record<CommentBodyType, string> {
    return {
      idea: 'white',
      inaccurate: 'error',
      'new-idea': 'white',
      'new-inaccurate': 'error',
      'new-question': 'info',
      question: 'info'
    }
  }

  get placeholder () {
    switch (this.type) {
      case 'question': return 'Ask question, @people'
      case 'inaccurate': return 'Mark as inaccurate, @people'
      case 'idea': return 'Add idea, @people'
      default: return 'Select comment type first'
    }
  }

  get createButton () {
    switch (this.type) {
      case 'question': return 'Ask question'
      case 'inaccurate': return 'Mark as inaccurate'
      case 'idea': return 'Add idea'
      default: return 'Add comment'
    }
  }

  @Watch('type')
  onTypeChanged (type: CommentBodyType | null, prevType: CommentBodyType | null) {
    if (type !== prevType) {
      this.focusContent()
    }
  }

  mounted () {
    this.menuRef.open()
  }

  updateDimensions () {
    this.menuRef.updateDimensions()
  }

  focusContent () {
    this.contentRef?.quill?.focus()
  }

  async create () {
    if (!this.currentCommentHandleId) {
      return
    }

    const commentCreate: CommentRequired = {
      body: this.createBody(this.type)
    }
    const { comment, commentUpsert } = this.commentModule.generateComment(this.currentLandscape.id, this.currentVersion.id, commentCreate)
    this.commentModule.setComment(comment)
    this.commentModule.setActiveComment(comment)
    this.editorModule.addToTaskQueue({
      func: () => this.commentModule.commentUpsert({
        commentId: comment.id,
        landscapeId: this.currentLandscape.id,
        props: commentUpsert,
        versionId: this.currentVersion.id
      })
    })

    const diagramComment: DiagramComment = {
      commentId: comment.id,
      id: randomId(),
      x: this.position.x,
      y: this.position.y
    }
    const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.diagramId, {
      comments: {
        $add: {
          [diagramComment.id]: diagramComment
        }
      }
    })
    this.diagramModule.setDiagramContentVersion(diagramContent)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      })
    })

    await this.$replaceQuery({
      comment: {
        ...this.$removeQueryArray('new-idea', 'new-question', 'new-inaccurate'),
        ...this.$unionQueryArray(diagramComment.id)
      }
    })

    const tasks: Task[] = [{
      id: comment.id,
      props: commentCreate,
      type: 'comment-create'
    }, {
      id: diagramContent.id,
      props: diagramContentUpdate,
      type: 'diagram-content-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const revertTasks: Task[] = [{
      route: {
        ...this.$route,
        query: {
          ...this.$route.query,
          comment: this.$queryArray('comment').filter(o => o !== diagramComment.id)
        }
      },
      type: 'navigation'
    }, {
      id: diagramContent.id,
      props: {
        comments: {
          $remove: [diagramComment.id]
        }
      },
      type: 'diagram-content-update'
    }, {
      id: comment.id,
      type: 'comment-delete'
    }]

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })
  }

  createBody (type: Exclude<CommentBodyType, 'new-idea' | 'new-question' | 'new-inaccurate'>): CommentBody {
    switch (type) {
      case 'question': return {
        content: this.contentRef.getValue(),
        showContentPreview: true,
        status: 'open',
        type
      }
      case 'inaccurate': return {
        content: this.contentRef.getValue(),
        showContentPreview: true,
        status: 'open',
        type
      }
      case 'idea': return {
        content: this.contentRef.getValue(),
        showContentPreview: true,
        status: 'active',
        type
      }
    }
  }
}
